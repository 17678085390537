import React from 'react'

//Components
import GridCard from './GridCard'

export default function Grid({grid_h3_text, grid_card_1, grid_card_2, grid_card_3, grid_card_4, grid_card_5, grid_card_6}) {
  return (
    <section className="services">
      <div className="container">
      <h3>{grid_h3_text}</h3>
        <div className="grid">
          <GridCard content={grid_card_1}/>

          <GridCard content={grid_card_2}/>

          <GridCard content={grid_card_3}/>

          <GridCard content={grid_card_4}/>

          <GridCard content={grid_card_5}/>

          <GridCard content={grid_card_6}/>

        </div>
      </div>
    </section>
  )
}
