import React from 'react'

export default function TableHeader({column_1_header, column_2_header, background_color}) {
  return (
    <div className={background_color}>
        <div className='container'>
            <h6 className='column-1 header'>{column_1_header}</h6>
            <h6 className='column-2 header'>{column_2_header}</h6>
        </div>
    </div>
  )
}
