import React from 'react'

//Component
import FAQ from './FAQ'

export default function FAQList({faqs, h1_content}) {


  return (
    <section className='faq'>
        <div className='container'>
            {h1_content && <h2>{h1_content}</h2>}
            <div className='faq-wrapper'>
                {faqs.map((faq, index) => (
                    <React.Fragment key={index}>
                        <FAQ key={faq.question} question={faq.question} answer={faq.answer} index={index}/>
                        {/*if not last item, add divider*/}
                        {index !== faqs.length - 1 && <hr />}
                    </React.Fragment>
                ))}
            </div>
      </div>
    </section>
  )
}
