import React from 'react'

//Styles
import '../../assets/scss/style.css';

//Components
import Hero from '../../components/Hero'
import Parallax from '../../components/Parallax';
import TwoColumn from '../../components/TwoColumn';
import ContactGeneral from '../../components/ContactGeneral';

//Assets
import hero_image from '../../assets/media/pexels-jacob-colvin-1761279.jpg'
import parallax_background_image from '../../assets/media/pexels-pixabay-417173-removebg-preview.png'
import two_column_image_1 from '../../assets/media/pexels-aleksey-kuprikov-3493777.jpg'
import two_column_image_2 from '../../assets/media/pills-transparent.png'

//Section Variables
const hero_alt_text="bridge leading into forest"
const hero_h1_text="PoloMar Health"
const hero_h6_text="The Gateway to Global Medicines"

const parallax_h4_text="About Us"
const parallax_p_text="PoloMar Health helps patients with unmet medical needs identify sources of novel drug products and formulations, prescribed by their physician, that are not readily accessible in the U.S. We work as a guide for patients and their physicians by identifying, qualifying, and coordinating the complex process involved in accessing these important medicines."
const parallax_button_text="Learn More"

const two_column_alt_text_1="bridge with white cloud above"
const two_column_h3_text_1="How We Can Help"
const two_column_p_text_1="If you are a patient in the United States and require a medicine that is only available overseas or is in late stages of clinical development, the process for accessing this medicine can be overwhelming. At PoloMar Health, we are experts in navigating this nuanced and complicated process. Our network of global drug suppliers, combined with our team of professionals can help facilitate this process and may be able to help you to gain access to foreign medicines, novel compounded products, and other investigational medicines. Our mission is to help patients that need important investigational medicines by using rigorous quality standards to identify suppliers and navigate the path to access."
const two_column_button_text_1="Learn More"
const two_column_link_location_1="/how-we-work"

const two_column_alt_text_2="spilled pill bottle"
const two_column_h3_text_2="Explore Products"
const two_column_p_text_2="We can assist patients in accessing many products through personal importation and expanded access INDs. The list of products is ever-changing, so please check in periodically and be sure to subscribe for updates."
const two_column_button_text_2="Learn More"
const two_column_link_location_2="/products#products-table"

const contact_left_h4_text="Have any questions?"
const contact_left_p_text="To learn more about PoloMar Health and our services, or to request any information, please enter your details and we'll be in touch!"
const contact_info_h4_text="Contact Us"
const contact_right_h4_text="Let's Connect"
const address_icon_alt="address icon"
const email_icon_alt="email icon"

export default function Home({email, address, email_icon, address_icon}) {
  return (
    <>
        <Hero
            image={hero_image}
            alt={hero_alt_text}
            bottom_overlay={true}
            full_overlay={true}
            h1_content={hero_h1_text}
            h6_content={hero_h6_text}
        />
        <Parallax
            background_image={parallax_background_image}
            button_text={parallax_button_text}
            overlay={true}
            h4_content={parallax_h4_text}
            p_content={parallax_p_text}
        />

        <TwoColumn
            image={two_column_image_1}
            alt={two_column_alt_text_1}
            h3_content={two_column_h3_text_1}
            p_content={two_column_p_text_1}
            has_button={true}
            button_text={two_column_button_text_1}
            layout_type={"reverse"}
            link_location={two_column_link_location_1}
            text_wrapper_height="medium"
            container_size={""}
            text_alignment={""}
            font_weight={""}
            extra_padding={""}
            objectFit={""}
        />

        <TwoColumn
            image={two_column_image_2}
            alt={two_column_alt_text_2}
            h3_content={two_column_h3_text_2}
            p_content={two_column_p_text_2}
            has_button={true}
            button_text={two_column_button_text_2}
            layout_type={"full-width"}
            link_location={two_column_link_location_2}
            text_wrapper_height="large"
            container_size={""}
            text_alignment={""}
            font_weight={""}
            extra_padding={""}
            objectFit={"object-fit"}
        />

        <ContactGeneral
            background={'fade-background'}
            left_h4_text={contact_left_h4_text}
            left_p_text={contact_left_p_text}
            contact_info_h4_text={contact_info_h4_text}
            email={email}
            right_h4_text={contact_right_h4_text}
            address={address}
            address_icon={address_icon}
            email_icon={email_icon}
            address_icon_alt={address_icon_alt}
            email_icon_alt={email_icon_alt}
        />
    </>
  )
}
