import React from 'react'

export default function Hero({image, alt, bottom_overlay, full_overlay, h1_content, h6_content}) {
  return (
    <div id="hero" className="large">
        <div className="background-wrapper">
            {full_overlay && <div className="full-overlay"></div>}
            {bottom_overlay && <div className='bottom-overlay'></div>}
            <img src={image} alt={alt}/>
        </div>
        <div className="container">
            <div className="text-wrapper">
                <h1>{h1_content}</h1>
                {h6_content && <h6>{h6_content}</h6>}
            </div>
        </div>
    </div>
  )
}
