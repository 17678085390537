import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop({children}) {
    const { pathname } = useLocation();
    const targetRef = useRef(null);
    let targetSet = false;
  
    useEffect(() => {
      window.scrollTo(0, 0);
      if (targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        targetSet = true;
      }
    }, [pathname]);

    useEffect(() => {
        const observer = new MutationObserver(mutations => {
          requestAnimationFrame(() => {
            mutations.forEach(mutation => {
              if (mutation.addedNodes.length) {
                mutation.addedNodes.forEach(node => {
                  if (node.hasOwnProperty('id') && node.id === targetRef.current.id && targetSet) {
                    node.scrollIntoView({ behavior: 'auto', block: 'start' });
                    observer.disconnect();
                  }
                });
              }
            });
          });
        });
        observer.observe(document.body, { childList: true });
        return () => observer.disconnect();
      }, [targetSet]);
      
    return <>{children}</>;
}
  

export default ScrollToTop;