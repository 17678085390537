import React, { useEffect, useState } from 'react'
import ScrollTrigger from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';
import axios from 'axios';
import { run } from '../Mailchimp';


export default function ContactGeneral({background, left_h4_text, left_p_text, contact_info_h4_text, email, right_h4_text, address, address_icon, email_icon, address_icon_alt, email_icon_alt, contact_left_p_text_2}) {

    const nameRegex = /^[a-zA-Z-]+$/;
    const otherRegex = /^[a-zA-Z0-9-]+$/;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const phoneRegex = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    const messageRegex = /^[a-zA-Z0-9.,-]+$/;



    const products = [
        { label: "Product of Interest"},
        { value: "BCG Vaccine", label: "BCG Vaccine"},
        { value: "Topical Diacerein", label: "Topical Diacerein"},
        { value: "Domperidone", label: "Domperidone"},
        { value: "Cinnarizine/Dimenhydrinate", label: "Cinnarizine/Dimenhydrinate"},
        { value: "Interferon Alpha-2B", label: "Interferon Alpha-2B"},
    ]

   useEffect(() => {
        contactInit()
    }, [])

    function contactInit() {
        let contactForm = document.querySelector('.contact');

        if(contactForm.classList.contains('green-background')){
            gsap.registerPlugin(ScrollTrigger);
            let smallCircleBottomLeft = document.querySelector('.small-circle.bottom-left');
            let smallCircleTopRight = document.querySelector('.small-circle.top-right');
            let largeCircle = document.querySelector('.large-circle');
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: contactForm,
                    start: 'top 0%',
                    endTrigger: document.documentElement,
                    scrub: true,
                    //markers: true
                }
            });
            tl.to(smallCircleBottomLeft, { y: '-100%', duration: 1})
            tl.to(smallCircleTopRight, {y: '100%', duration: 1}, '-=1')
        }
    }

    function killAll() {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
        contactInit()
    }

    function debounce(func){
        var timer;
        return function(event){
            if(timer) clearTimeout(timer);
            timer = setTimeout(func,300,event);
        };
    }

    window.addEventListener("resize", debounce(function(e){
        killAll()
    }));

    window.addEventListener('load', (event) => {
        contactInit();
    });

    //form interactions

    const [formState, setFormState] = useState({});
    const [formError, setFormError] = useState(false);
    const [subscribe, setSubscribe] = useState(false);


    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setFormState((prevState) => ({
          ...prevState,
          [name]: {
            value: value,
            focus: prevState[name] ? prevState[name].focus : false,
            error: prevState[name] ? prevState[name].error : undefined
          }
        }));
    }

      const handleFocus = (event) => {
        const target = event.target;
        const name = target.name;

        setFormState((prevState) => ({
          ...prevState,
          [name]: {
            value: prevState[name] ? prevState[name].value : '',
            focus: true,
            error: prevState[name] ? prevState[name].error : undefined
          }
        }));
      }

      const handleBlur = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        let error;

        if (name == 'email') {
          if(!emailRegex.test(value)){
            error = 'Invalid Email';
            setFormError(true);
          }
          else {
            setFormError(false);
          }
        }
        else if (name == 'phone') {
          if(!phoneRegex.test(value)){
            error = 'Invalid Phone Number';
            setFormError(true);
          }
          else {
            setFormError(false);
          }

        }
        else if (name == 'firstName') {
          if(!nameRegex.test(value)){
            error = 'Invalid First Name';
            setFormError(true);
          }
          else {
            setFormError(false);
          }
        }
        else if (name == 'lastName') {
          if(!nameRegex.test(value)){
            error = 'Invalid Last Name';
            setFormError(true);
          }
          else {
            setFormError(false);
          }
        }
        else if (name == 'message') {
          if(!messageRegex.test(value)){
            error = 'Invalid character(s) in Message';
            setFormError(true);
          }
          else {
            setFormError(false);
          }
        }
        else { //selectOther
           if(!otherRegex.test(value)){
              error = 'Invalid characater(s) in Other';
              setFormError(true);
           }
           else {
            setFormError(false);
          }
        }


        if (value === '') {
          setFormState((prevState) => ({
            ...prevState,
            [name]: {
              value: '',
              focus: false,
              error: undefined
            }
          }));
        } else {
          setFormState((prevState) => ({
            ...prevState,
            [name]: {
              value: prevState[name] ? prevState[name].value : '',
              focus: true,
              error
            }
          }));
        }
      }

      const handleFormSubmit = async (event) => {
        event.preventDefault();
        console.log(formError);
        try {
          if(!formError) {
              if (subscribe) {
                await handleMailchimp(event);
              }
              await handleMailGun(event);
          }
          //replace '#form .right' inner html with success message
          document.querySelector('#contact .right').innerHTML = '<h5>Message has been sent!</h5><p>We will be in touch with you shortly.</p>';
        } catch {
          console.log('error');
        }
      }

      const handleMailchimp = (event) => {
        const subscribingUser = {
          firstName: formState.firstName.value,
          lastName: formState.lastName.value,
          email: formState.email.value
        };

        //https://us-central1-polomar-health.cloudfunctions.net/app
        //http://127.0.0.1:5001/polomar-health/us-central1/app
        axios({
          method: 'post',
          url: 'https://us-central1-polomar-health.cloudfunctions.net/app',
          data: subscribingUser
        })
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.log(error);
        });
      }

      const handleMailGun = (event) => {
        event.preventDefault();

          let emailFirstName = formState.firstName.value;
          let emailLastName = formState.lastName.value;
          let emailEmail = formState.email.value;
          let emailPhone = formState.phone.value;
          let emailMessage = formState.message.value;
          let emailSelect = formState.productSelected.value;
          let emailSelectOther = formState.selectOther?.value || '';

          let otherValue = '';

          if (emailSelectOther == 'Other') {
            otherValue =
            'Select Other: ' + emailSelectOther
            + '\n\n';
          }

          //prepend with message
          let formStateString =
            'You just received a new form submission: '
            + '\n\n'
            + 'First Name: ' + emailFirstName
            + '\n\n'
            + 'Last Name: ' + emailLastName
            + '\n\n'
            + 'Email: ' + emailEmail
            + '\n\n'
            + 'Phone: ' + emailPhone
            + '\n\n'
            + 'Message: ' + emailMessage
            + '\n\n'
            + 'Select: ' + emailSelect
            + '\n\n'
            + otherValue
          ;


          console.log(formState);



          const formData = {
            to: "jon@jonjones.me, info@polomarhealth.com",
            from: "donotreply@mail.polomarhealth.com",
            subject: "Contact Page Form Submission",
            text: formStateString
          };

          console.log(formData);

          //https://us-central1-polomar-health.cloudfunctions.net/app
          //http://127.0.0.1:5001/polomar-health/us-central1/app
          axios({
            method: 'post',
            url: 'https://us-central1-polomar-health.cloudfunctions.net/testFunction',
            data: formData
          })
          .then(response => {
            console.log(response.data);
          })
          .catch(error => {
            console.log(error);
          });
        

      }


    useEffect(() => {
        const inputFields = ['firstName', 'lastName', 'email', 'phone', 'message'];
        inputFields.forEach((field) => {
          if (formState[field]) {
            document.querySelector(`#${field}`).value = formState[field].value;
            if (formState[field].value != '') {
                formState[field].focus = true;
            } else {
                formState[field].focus = false;
            }
          }
        });
    }, [formState]);

    const [selectedOption, setSelectedOption] = useState('option1');
    const [otherOption, setOtherOption] = useState('');

    const handleChange = (event) => {
      const { value } = event.target;
      const name = event.target.name;



      if (value === 'other') {
        setSelectedOption('other');
      } else {
        setSelectedOption(value);
        setOtherOption('');
      }

      setFormState((prevState) => ({
        ...prevState,
        [name]: {
          value: value,
          focus: prevState[name] ? prevState[name].focus : false,
          error: prevState[name] ? prevState[name].error : undefined
        }
      }));

    };


  return (
    <section id="contact" className={`contact ${background}`}>
      {background == "fade-background" ? (
        <div className={background}></div>
      ) : (
        <>
          <div className={background}></div>
          <div className="small-circle bottom-left"></div>
          <div className="large-circle"></div>
          <div className="small-circle top-right"></div>
        </>
      )}
      <div className="container">
        <div className="left">
          <h4>{left_h4_text}</h4>
          <p>{left_p_text}</p>
          <p>{contact_left_p_text_2}</p>
          <div className="contact-info">
            <h4>{contact_info_h4_text}</h4>
            <div className="content-wrapper">
              <img src={address_icon} alt={address_icon_alt} />
              <h6 dangerouslySetInnerHTML={{__html: address}} />
            </div>
            <div className="content-wrapper">
              <img src={email_icon} alt={email_icon_alt} />
              <h6>
                <a href={`mailto:${email}`}>{email}</a>
              </h6>
            </div>
          </div>
        </div>
        <div className="right">
          {right_h4_text && <h4>{right_h4_text}</h4>}
          <form id="general-contact">
            <div className="row">
            <div className={`form-field ${formState.firstName && formState.firstName.focus ? 'active' : ''}`}>
              <label className={formState.firstName?.error && 'red'}>
                    {formState.firstName?.error || "First Name"}
              </label>


                <input
                    id="firstName"
                    type="text"
                    name="firstName"
                    value={formState.firstName ? formState.firstName.value : ''}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleInputChange}
                />
              </div>
              <div className={`form-field ${formState.lastName && formState.lastName.focus ? 'active' : ''}`}>
                <label className={formState.lastName?.error && 'red'}>
                      {formState.lastName?.error || "Last Name"}
                </label>
                <input
                    id="lastName"
                    type="text"
                    name="lastName"
                    value={formState.lastName ? formState.lastName.value : ''}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
                <div className={`form-field ${formState.email && formState.email.focus ? 'active' : ''}`}>
                    <label className={formState.email?.error && 'red'}>
                      {formState.email?.error || "Email"}
                    </label>
                    <input
                        id="email"
                        type="text"
                        name="email"
                        value={formState.email ? formState.email.value : ''}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleInputChange}
                        inputMode="email"
                    />
                </div>
            </div>
            <div className="row">
                <div className={`form-field ${formState.phone && formState.phone.focus ? 'active' : ''}`}>
                    <label className={formState.phone?.error && 'red'}>
                      {formState.phone?.error || "Phone"}
                    </label>
                    <input
                        id="phone"
                        type="text"
                        name="phone"
                        value={formState.phone ? formState.phone.value : ''}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={handleInputChange}
                        inputMode="phone"
                    />
                </div>
            </div>
            <div className="row">
              <div className="form-field">
                <label className={formState.message?.error && 'red'}>
                </label>
                <textarea
                    id="message"
                    placeholder="Message"
                    name="message"
                    value={formState.message ? formState.message.value : ''}
                    onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="form-field">
                <select value={selectedOption} onChange={handleChange} name="productSelected">
                {products.map((product, index) => (
                    <option key={index} value={product.value}>
                    {product.label}
                    </option>
                ))}
                <option value="other">Other</option>
                </select>
              </div>
            </div>
            <div className='row'>
                <div className={`form-field ${formState.selectOther && formState.selectOther.focus ? 'active' : ''}`}>
                    {selectedOption === 'other' && (
                        <>
                          <label className={formState.selectOther?.error && 'red'}>
                            {formState.phone?.error || "Enter Other Product:"}
                          </label>
                            <input
                                id="selectOther"
                                name="selectOther"
                                type="text"
                                value={otherOption}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={(event) => setOtherOption(event.target.value)}
                              />
                        </>
                    )}
                </div>
            </div>
            <div className="row">
              <div className="form-field">
                <div className="subscribe-wrapper">
                  <input type="checkbox" onClick={() =>  setSubscribe(!subscribe)} />
                  <span>Subscribe for Updates</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-field">
                <button className="btn" type="submit" onClick={handleFormSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
