import React from 'react'

export default function TableRow({column_1, column_2_text_1, column_2_text_2, background_color}) {
  return (
    <div className={background_color}>
        <div className='container'>
            <p className='column-1'>{column_1}</p>
            <div className='column-2'>
              <p>{column_2_text_1}</p>
              <p>{column_2_text_2}</p>
            </div>
        </div>
    </div>
  )
}
