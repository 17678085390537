import React from 'react'
import { Link } from 'react-router-dom'

export default function TwoColumn({image, alt, objectFit, h2_content, h3_content, p_content, has_button, button_text, layout_type, link_location, text_wrapper_height, container_size, text_alignment, font_weight, extra_padding}) {
  return (

    <section className={`two-column ${layout_type} ${extra_padding} ${objectFit}`}>
        <div className={`container ${container_size}`}>
            <div className="image-wrapper">
                <img src={image} alt={alt} />
            </div>
            <div className={`text-wrapper ${text_wrapper_height} ${text_alignment} ${font_weight}`}>
                {h3_content && <h3>{h3_content}</h3>}
                {h2_content && <h3>{h2_content}</h3>}
                <p>{p_content}</p>
                {has_button &&
                <div className="button-wrapper">
                    <Link to={link_location} className="btn">{button_text}</Link>
                </div> }
            </div>
        </div>
    </section>
  )
}
