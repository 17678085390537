import React from 'react'
import { useState } from 'react';

export default function FAQ({question, answer, index}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='question-wrapper' onClick={() => setIsOpen(!isOpen)}>
      <div className='icon-wrapper'>
        <h6 className='question' >{question}</h6>
        <h5>{isOpen ? '-' : '+'}</h5>
      </div>
      <p
        className='answer'
        dangerouslySetInnerHTML={{__html: answer}}
        style={
          {
            maxHeight: isOpen ? "250px" : 0,
            overflow: "hidden",
            transition: "max-height 0.3s ease-in-out, margin 0.3s ease-in-out",
            margin: isOpen ? "15px 0" : 0
          }
        }
      >
      </p>
    </div>
  )
}
