import React from 'react'

//Comoponents
import TableHeader from './TableHeader'
import TableRow from './TableRow'


export default function TwoColumnTable({column_1_header, column_2_header, row_1_column_1, row_1_column_2_text_1, row_1_column_2_text_2, row_2_column_1, row_2_column_2_text_1, row_2_column_2_text_2, row_3_column_1, row_3_column_2_text_1, row_3_column_2_text_2, row_4_column_1, row_4_column_2_text_1, row_4_column_2_text_2, row_5_column_1, row_5_column_2_text_1, row_5_column_2_text_2, row_6_column_1, row_6_column_2_text_1, row_6_column_2_text_2}) {
  return (
    <div id="products-table" className='two-column-table'>
          <TableHeader
            column_1_header={column_1_header}
            column_2_header={column_2_header}
            background_color={"black-green"}
          />
          <TableRow
            column_1={row_1_column_1}
            column_2_text_1={row_1_column_2_text_1}
            column_2_text_2={row_1_column_2_text_2}
            background_color={"dark-green"}
          />
          <TableRow
            column_1={row_2_column_1}
            column_2_text_1={row_2_column_2_text_1}
            column_2_text_2={row_2_column_2_text_2}
            background_color={"medium-green"}
          />
          <TableRow
            column_1={row_3_column_1}
            column_2_text_1={row_3_column_2_text_1}
            column_2_text_2={row_3_column_2_text_2}
            background_color={"dark-green"}
          />
          <TableRow
            column_1={row_4_column_1}
            column_2_text_1={row_4_column_2_text_1}
            column_2_text_2={row_4_column_2_text_2}
            background_color={"medium-green"}
          />
          <TableRow
            column_1={row_6_column_1}
            column_2_text_1={row_6_column_2_text_1}
            column_2_text_2={row_6_column_2_text_2}
            background_color={"dark-green"}
          />

    </div>
  )
}
