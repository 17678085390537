import React from 'react'
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'

export default function Header({Logo, email}) {

    const location = useLocation();

    useEffect(() => {
        const header = document.querySelector("header");

        // Remove all classes from the header element
        header.className = "";

        // Add a className based on the current pathname
        if (location.pathname === "/faq") {
            header.classList.add("solid");
        }
    }, [location]);

    var lastScrollTop = 0;
    function toggleHeaderClass() {
        var st = window.scrollY;

        if (st > lastScrollTop) {
          // Scrolling down
          if (st > 30) {
            document.querySelector("header").classList.remove("nav-down");
            document.querySelector("header").classList.add("nav-up");
          }
        } else {
          // Scrolling up
          document.querySelector("header").classList.remove("nav-up");
          document.querySelector("header").classList.add("nav-down");
        }

        if (st === 0) {
          // At the top of the window
          document.querySelector("header").classList.add("transparent");
        } else {
          document.querySelector("header").classList.remove("transparent");
        }

        lastScrollTop = st;
      }

      window.addEventListener("scroll", toggleHeaderClass);


      function handleMenuClick() {
        document.getElementById("menu").classList.toggle("show");
        document.querySelector(".mobile-menu").classList.toggle("active");
        document.querySelector(".close .mobile-menu").classList.toggle("active");
      }

  return (
    <>
      <header className="navbar">
        <div className='container'>
            <div className='logo'>
                <Link to="/">
                    <img src={Logo} alt="Logo" className="navbar-logo" />
                </Link>
            </div>
            <ul className="navbar-links">
                <li>
                    <Link to="/about-us">About Us</Link>
                </li>
                <li>
                    <Link to="/how-we-work">How We Work</Link>
                </li>
                <li>
                    <Link to="/products">Products</Link>
                </li>
                <li>
                    <Link to="/faq">FAQ</Link>
                </li>
                <li>
                    <Link to='/contact'>Contact Us</Link>
                </li>
            </ul>
            <div className="mobile-menu" onClick={handleMenuClick}>
                <div className="menu-bar one"></div>
                <div className="menu-bar two"></div>
                <div className="menu-bar three"></div>
            </div>
        </div>
      </header>

      <div id="menu">
          <div className="container">
              <div className="close" onClick={handleMenuClick}>
                  <div className="mobile-menu">
                      <div className="menu-bar one"></div>
                      <div className="menu-bar two"></div>
                      <div className="menu-bar three"></div>
                  </div>
              </div>
              <a className="logo" href="/">
                <img src={Logo} alt="Logo" className="navbar-logo" />
              </a>
              <ul className="navbar-links">
                  <li>
                      <Link to="/about-us" onClick={handleMenuClick}>About Us</Link>
                  </li>
                  <li>
                      <Link to="/how-we-work" onClick={handleMenuClick}>How We Work</Link>
                  </li>
                  <li>
                      <Link to="/products" onClick={handleMenuClick}>Products</Link>
                  </li>
                  <li>
                      <Link to="/faq" onClick={handleMenuClick}>FAQ</Link>
                  </li>
                  <li>
                      <Link to='/contact' onClick={handleMenuClick}>Contact Us</Link>
                  </li>
              </ul>
          </div>
          <div className="connect">
            <a href={`mailto:${email}`}>{email}</a>
          </div>
      </div>
    </>
  )
}
