import React from 'react'

export default function ProcessSlider({h3_content, process_slider_content}) {
  return (
    <section className='process'>
        <div className='container'>
            <h3>{h3_content}</h3>
            <div className='process-slider'>
                {
                    process_slider_content.map((content, index) => {
                        return (
                            <div key={index} className='process-slider-card'>
                                <div className='top'>
                                    <h3 className='card-number'>{index+1}</h3>
                                    <div className='image-wrapper'>
                                        <img src={content.image} alt={content.alt}/>
                                    </div>
                                </div>
                                <div className='bottom'>
                                    <div className='card-info'>
                                        <h6>{content.h6}</h6>
                                        <p>{content.content}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="step">
                {
                    process_slider_content.map((content, index) => {
                        return (
                            <h6 key={index}>Step {index+1}</h6>
                        )
                    })
                }
            </div>
      </div>
    </section>
  )
}
