import React from 'react'

//Components
import Hero from '../../components/Hero'
import TwoColumnTable from '../../components/TwoColumnTable'
import TwoColumn from '../../components/TwoColumn'
import ContactProduct from '../../components/ContactProduct'

//Assets
import hero_image from '../../assets/media/pexels-luis-del-río-15286.jpg'
import two_column_image from '../../assets/media/single-yellow-pill-bottle-spilled.png'


const hero_alt_text="luscious green overgrown forest"
const hero_h1_text="Products"

const column_1_header="Product Name"
const column_2_header="Additional Information"

const row_1_column_1="BCG Vaccine"
const row_1_column_2_text_1="Indication: Bladder Cancer"
const row_1_column_2_text_2="Route of Admin: Intravesical"

const row_2_column_1="Diacerein"
const row_2_column_2_text_1="Investigational Indication: Epidermolysis Bullosa Simplex"
const row_2_column_2_text_2="Route of Admin: Topical"

const row_3_column_1="Domperidone"
const row_3_column_2_text_1="Investigational Indication: Severe Gastroparesis"
const row_3_column_2_text_2="Route of Admin: Oral"

// const row_4_column_1="Cinnarizine / Dimenhydrinate"
// const row_4_column_2_text_1="Indication: Vertigo"
// const row_4_column_2_text_2="Route of Admin: Oral"

// const row_6_column_1="Interferon Alpha-2B"
// const row_6_column_2_text_1="Indication: Genital Warts"
// const row_6_column_2_text_2="Route of Admin: Topical"

const row_4_column_1="Amisulpride"
const row_4_column_2_text_1="Indication: Schizophrenia and Schizoaffective Disorder"
const row_4_column_2_text_2="Route of Admin: Oral"

const row_6_column_1="IM Clozapine"
const row_6_column_2_text_1="Schizophrenia, Schizoaffective Disorder, and Bipolar Disorder"
const row_6_column_2_text_2="Route of Admin: Intramuscular"

const two_column_h2_text="Order Process"
const two_column_p_text="If you are interested in any of the above products, please complete the below order form. If you have an interest in a product not listed above, please reach out via our Contact Us page. We look forward to working with you!"
const two_column_image_alt="spilled pill bottle"

const contact_info_h4_text="Contact Us"
const contact_right_h4_text="Product Order"
const address_icon_alt="address icon"
const email_icon_alt="email icon"

export default function Products({email, address, email_icon, address_icon}) {
  return (
    <>
        <Hero
            image={hero_image}
            alt={hero_alt_text}
            bottom_overlay={true}
            full_overlay={false}
            h1_content={hero_h1_text}
        />

        <TwoColumnTable
          column_1_header={column_1_header}
          column_2_header={column_2_header}
          row_1_column_1={row_1_column_1}
          row_1_column_2_text_1={row_1_column_2_text_1}
          row_1_column_2_text_2={row_1_column_2_text_2}
          row_2_column_1={row_2_column_1}
          row_2_column_2_text_1={row_2_column_2_text_1}
          row_2_column_2_text_2={row_2_column_2_text_2}
          row_3_column_1={row_3_column_1}
          row_3_column_2_text_1={row_3_column_2_text_1}
          row_3_column_2_text_2={row_3_column_2_text_2}
          row_4_column_1={row_4_column_1}
          row_4_column_2_text_1={row_4_column_2_text_1}
          row_4_column_2_text_2={row_4_column_2_text_2}
          row_6_column_1={row_6_column_1}
          row_6_column_2_text_1={row_6_column_2_text_1}
          row_6_column_2_text_2={row_6_column_2_text_2}
        />

        <TwoColumn
            image={two_column_image}
            alt={two_column_image_alt}
            h2_content={two_column_h2_text}
            p_content={two_column_p_text}
            has_button={false}
            layout_type={"reverse wide-text"}
            text_wrapper_height={"normal"}
            container_size={""}
            text_alignment={"left"}
            font_weight={"normal"}
        />

        <ContactProduct
            contact_info_h4_text={contact_info_h4_text}
            email={email}
            right_h4_text={contact_right_h4_text}
            address={address}
            address_icon={address_icon}
            email_icon={email_icon}
            address_icon_alt={address_icon_alt}
            email_icon_alt={email_icon_alt}
        />
    </>
  )
}