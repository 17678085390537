import React from 'react'

//Components
import Hero from '../../components/Hero'
import Grid from '../../components/Grid'
import ProcessSlider from '../../components/ProcessSlider'

//Assets
import hero_image from '../../assets/media/pexels-luna-groothedde-12673808.jpg'
import paperwork_fill from '../../assets/media/noun-paperwork-fill-5249511-FAFAFA.svg'
import paperwork_submit from '../../assets/media/noun-paperwork-3194818-FAFAFA.svg'
import delivery from '../../assets/media/noun-door-to-door-delivery-1858510-FAFAFA.svg'
import quality from '../../assets/media/noun-quality-assurance-1327175-FAFAFA.svg'
import importation from '../../assets/media/noun-import-4139657-FAFAFA.svg'
import product from '../../assets/media/noun-product-5404496-FAFAFA.svg'




const hero_alt_text="stone staircase leading down on trail"
const hero_h1_text="How We Work"

const grid_h3_text="Services Offered"
const grid_card_1="Assisting in accessing novel therapeutic products, formulations, and other investigational products not readily available in the U.S."
const grid_card_2="Assisting patients and physicians in navigating the complex regulatory and logistical processes required to access foreign or other investigational products"
const grid_card_3="Facilitating compounding services to create novel formulations of products tailored to a patient’s specific condition and needs"
const grid_card_4="Ensuring product quality with manufacturers that have met rigorous criteria"
const grid_card_5="Medical affairs personnel to support physician and patient awareness of our services and important information on the products/suppliers for which we help provide access"
const grid_card_6="Assisting patients in securing the reimbursement of products wherever possible"
const process_slider_content = [
  {"image":product, "alt":"indentifying product", "content":"Please identify the product sought and submit the information in the “Order Process” box found under the Products tab. After submitting this information, we will contact you through email with the next steps.", "h6":"Patient/Physician identifies product"},
  {"image":paperwork_fill, "alt":"filling out paperwork", "content":"We will help patients and physicians complete the necessary paperwork needed for your specific product. Depending on the product, the documentation will vary, and we will request any additional items that we may need. We are here to assist and guide you throughout the process and are happy to answer any questions that you may have.", "h6":"Fill out paperwork"},
  {"image":paperwork_submit, "alt":"submit paperwork", "content":"Patients and/or physicians will submit paperwork to our secure email account. PoloMar will then coordinate this information to the appropriate parties on behalf of the patient/physician. Upon submission of your paperwork, we will charge the initial upfront payment. Following receipt of your product, the second payment will be processed.", "h6":"Paperwork submission"},
  {"image":quality, "alt":"quality assurance", "content":"Our top priority is ensuring the safety and quality of the products. We have established relationships with a variety of manufacturers that have met rigorous criteria. If you have any questions or concerns about any suppliers, please do not hesitate to ask.", "h6":"Quality assurance"},
  {"image":importation, "alt":"import product", "content":"If importation is required, we may work with a customs broker to ensure the proper and safe importation of your product. We will assist you and your physician in completing the necessary documentation for correct importation.", "h6":"Importation"},
  {"image":delivery, "alt":"delivery", "content":"At the final stage, your product will be delivered to your physician’s office, where you can pick up your medication and begin treatment. Developed specifically for pharmaceutical shipments, you can rest assured that your product will be safe and protected during transit.", "h6":"Delivery"}
]
const process_slider_h3="The Process"

export default function HowWeWork() {
  return (
    <>
        <Hero
            image={hero_image}
            alt={hero_alt_text}
            bottom_overlay={true}
            full_overlay={false}
            h1_content={hero_h1_text}
        />
        <Grid
            grid_h3_text={grid_h3_text}
            grid_card_1={grid_card_1}
            grid_card_2={grid_card_2}
            grid_card_3={grid_card_3}
            grid_card_4={grid_card_4}
            grid_card_5={grid_card_5}
            grid_card_6={grid_card_6}
        />
        <ProcessSlider
          h3_content={process_slider_h3}
          process_slider_content={process_slider_content}
        />
    </>
  )
}
