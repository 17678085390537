import React from 'react'

import FAQList from '../../components/FAQList'

export default function FrequentlyAskedQuestions() {
    const faq_h1_text="FAQ"
    const qas = [
      {"question":"Who is PoloMar Health for?" , "answer":"PoloMar Health’s goal is to help patients and physicians identify sources of necessary therapies and help facilitate access to treatments that are not readily accessible in the US."},
      {"question":"How does the process work to get my prescription/treatment?" , "answer":"First, you need to speak to your physician and confirm their interest in supporting your treatment with one of the listed medications or another medication that is not currently available in the US. Then, we can help identify the medical necessity forms that will need to be completed to ensure proper handling. In most circumstances, the medicine will be shipped directly to your physician’s office where you can pick it up and begin treatment."},
      {"question":"Is my medical information secure?" , "answer":"Yes. We take privacy seriously and have implemented strict security protocols to protect your information. Please read our Privacy Policy for additional information."},
      {"question":"How do I submit my forms?" , "answer":"There will be an email provided that can receive all forms that are required for processing and be manually input into the system to ensure you have the necessary documentation."},
      {"question":"What do I do if I don't already have a prescription?" , "answer":"You will need to speak with your physician about the need for the medicine and they will provide you with the correct details.  Please feel free to have your physician Contact Us if they have any questions or would like to learn more about our services or the products that we help patients to access."},
      {"question":"How quickly will I get my medication?" , "answer":"Once all the information is correctly uploaded and all requirements have been filled, medications should generally be shipped within a few days. However, please keep in mind that these medicines may be coming from overseas, so transit times may vary."},
      {"question":"Will insurance cover the cost of my medication?" , "answer":"Our team will work closely with your insurance providers to provide the best approach to handling the cost of the medication wherever possible."},
      {"question":"When am I charged?" , "answer":"You will be charged 50% once all the paperwork is received and 50% when the drug is shipped."},
      {"question":"What is your return policy?" , "answer":"Unfortunately, we cannot accept returns of any prescription medicines due to potential contamination and liability purposes. If there is an error with your order, please contact us at <a href='mailto:info@polomarhealth.com'>info@polomarhealth.com</a>."},
      {"question":"What if I have questions?" , "answer":"Submit any inquiries to our email, <a href='mailto:info@polomarhealth.com'>info@polomarhealth.com</a>, or our text box on the Contact Us tab and we will follow up with answers and any additional information."}
    ]

  return (
    <>
        <FAQList
            h1_content={faq_h1_text}
            faqs={qas}
        />
    </>
  )
}
