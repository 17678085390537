import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer({Logo, email, address}) {
  return (
    <footer>
        <div className='container'>
            <div className='logo'>
                <Link to="/">
                    <img src={Logo} alt="Logo" className="navbar-logo" />
                </Link>
            </div>
            <div>
                <p dangerouslySetInnerHTML={{__html: address}} />
            </div>
            <div>
                <p><a href={`mailto:${email}`}>{email}</a></p>
            </div>
        </div>
    </footer>
  )
}
