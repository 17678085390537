import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

//Pages
import Home from './pages/home/Home';
import About from './pages/about-us/AboutUs';
import Contact from './pages/contact/Contact';
import HowWeWork from './pages/how-we-work/HowWeWork';
import Products from './pages/products/Products';

//Components
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

//Styles
import './App.css';
import './assets/scss/style.css';

//Assets
import Logo from './assets/media/polomar-logo.svg'
import PolomarIcon from './assets/media/polomar-icon.svg'
import email_icon from './assets/media/noun-email-5344368-FAFAFA.svg'
import address_icon from './assets/media/noun-address-2508905-FAFAFA.svg'
import FrequentlyAskedQuestions from './pages/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import close from './assets/media/close.svg'

//These variables can be used anywhere
const email="info@polomarhealth.com"
const address="303 S Broadway <br /> Suite 125 <br /> Tarrytown, NY 10591"



function App() {

  const closeModal = () => {
    document.getElementById('modal').classList.remove('active');
    document.getElementById('modal-background').classList.remove('active');
    document.getElementById('modal').classList.add('hide');
    document.getElementById('modal-background').classList.add('hide');
    document.getElementById('modal-content').innerHTML = '';
    document.querySelector('body').classList.remove('no-scroll');
  }

  return (
    <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            <Header Logo={Logo} email={email} />

            <div id="modal" className='hide'>
              <img id="close" src={close} alt="close" onClick={closeModal} />
              <div id="modal-content"></div>
            </div>
            <div id="modal-background" className='hide' onClick={closeModal}></div>

            <Routes>
              <Route path="/" element={<Home email={email} address={address} email_icon={email_icon} address_icon={address_icon}/>} />
              <Route path="/about-us" element={<About />} />
              <Route path="/how-we-work" element={<HowWeWork />} />
              <Route path="/products" element={<Products email={email} address={address} email_icon={email_icon} address_icon={address_icon} />} />
              <Route path="/faq" element={<FrequentlyAskedQuestions />} />
              <Route path="/contact" element={<Contact email={email} address={address} email_icon={email_icon} address_icon={address_icon}/>} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer Logo={PolomarIcon} address={address} email={email}/>
          </ScrollToTop>
        </BrowserRouter>
    </div>
  );
}

export default App;
