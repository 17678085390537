import React from 'react'

//Components
import Hero from '../../components/Hero'
import SingleColumn from '../../components/SingleColumn'
import TwoColumn from '../../components/TwoColumn'

//Assets
import hero_image from '../../assets/media/pexels-valentin-antonucci-1275393.jpg'
import two_column_image from '../../assets/media/pexels-s-migaj-824512.jpg'
import single_column_image_1 from '../../assets/media/two-individual-blue-pills-transparent.png'
import single_column_image_2 from '../../assets/media/yellow-pill-bottles-spilled-transparent.png'

const hero_alt_text="person tossing small globe into the air"
const hero_h1_text="About Us"

const single_column_p_text_1="PoloMar Health helps patients with unmet medical needs identify sources of life-changing therapies, prescribed by their physician, that are not approved in the U.S. These products may be available overseas or may be investigational products still in development. Some of these therapies may hold the potential to dramatically improve the quality of life for patients.  Our focus is providing patients with rare or serious diseases access to novel pharmaceuticals and other therapeutic products that their physician deems necessary for treatment in an efficient manner by leveraging FDA-identified mechanisms for product access."
const single_column_image_alt_text_1="2 blue pills"

const single_column_p_text_2="PoloMar Health draws on its deep knowledge of unique pharmaceutical products, formulations, and drug delivery technologies, as well as its global relationships with drug development, manufacturing, and distribution firms, combined with regulatory and reimbursement expertise to deliver a top tier service to patients and the physicians who treat them."
const single_column_image_alt_text_2="bottle of round white pills"

const two_column_p_text="Currently, U.S. patients that need access to foreign or other investigational medicines are faced with a plethora of challenges, including inconsistent supply, uncertain quality, and complicated bureaucracy.  We exist to simplify this process and help you and your physician address these issues."

export default function AboutUs() {
  return (
    <>
        <Hero
          image={hero_image}
          alt={hero_alt_text}
          bottom_overlay={true}
          full_overlay={true}
          h1_content={hero_h1_text}
        />

        <SingleColumn
          container_size={"small"}
          single_column_p_text={single_column_p_text_1}
          background_color={""}
          extra_padding={"bottom-padding"}
        />

        <SingleColumn
          single_column_p_text={single_column_p_text_2}
          background_color={"green"}
          extra_padding={"extra-padding"}
          container_size={""}
          image_position_2={"bottom-right"}
          image_2={single_column_image_2}
          alt_2={single_column_image_alt_text_2}
          image_1={single_column_image_1}
          alt_1={single_column_image_alt_text_1}
          image_position_1={"top-left"}
        />

        <TwoColumn
          image={two_column_image}
          p_content={two_column_p_text}
          container_size={"medium"}
          text_wrapper_height={"extra-small"}
          text_alignment={"center"}
          font_weight={"bold"}
          layout_type={""}
          extra_padding={"extra-padding"}
        />
    </>
  )
}
