import React from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom'
import ScrollTrigger from 'gsap/ScrollTrigger';
import { gsap } from 'gsap';


export default function Parallax({background_image, button_text, overlay, h4_content, p_content}) {
    useEffect(() => {
      init()
    }, [])

    //ScrollTrigger for Parallax
    function init() {
        gsap.registerPlugin(ScrollTrigger);
        let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight);

        gsap.utils.toArray("div.parallax").forEach((parallax, i) => {
            parallax.bg = parallax.querySelector(".bg");

            // the first image (i === 0) should be handled differently because it should start at the very top.
            // use function-based values in order to keep things responsive
            gsap.fromTo(parallax.bg, {
                backgroundPosition: () => "50% 0px"
            }, {
                backgroundPosition: () => `50% ${-window.innerHeight * (1 - getRatio(parallax))}px`,
                ease: "none",
                scrollTrigger: {
                    trigger: parallax,
                    start: "top 100%",
                    end: "bottom top",
                    scrub: true,
                    //markers: true
                }
            });
        });
    }

    function killAll() {
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
        init()
    }

    function debounce(func){
        var timer;
        return function(event){
            if(timer) clearTimeout(timer);
            timer = setTimeout(func,300,event);
        };
    }

    window.addEventListener("resize", debounce(function(e){
        killAll()
    }));

    window.addEventListener('load', (event) => {
        init();
    });

  return (
    <div className="parallax large no-margin-bottom no-margin-top">
        <div className="bg" style={{"backgroundImage":`url(${background_image}`}}></div>
        {overlay && <div className="overlay"></div>}
        <div className="container">
            <div className="text-wrapper">
                <h4>{h4_content}</h4>
                <p>{p_content}</p>
                <div className="button-wrapper">
                    <Link to='/about-us' className='btn' >{button_text}</Link>
                </div>
            </div>
        </div>
    </div>
  )
}
