import React from 'react'

export default function SingleColumn({container_size, single_column_h5_text, single_column_h6_text,  single_column_p_text, background_color, image_1, image_2, image_position_1, image_position_2, alt_1, alt_2, extra_padding, text_alignment, ol}) {
  return (
    <section className={`single-column ${background_color} ${extra_padding}`}>
        <div className={`container ${container_size}`}>
            <div className={`text-wrapper ${text_alignment}`}>
                {single_column_h5_text && <h5>{single_column_h5_text}</h5>}
                {single_column_h6_text && <h5>{single_column_h6_text}</h5>}
                {single_column_p_text && <p>{single_column_p_text}</p>}
                {ol &&
                  <ol>
                      {ol.map((item, index) => (
                          <li key={index}>{item}</li>
                      ))}
                  </ol>
                }
            </div>
            {image_1 &&
              <div className={`image-wrapper ${image_position_1}`}>
                <img src={image_1} alt={alt_1}/>
              </div>
            }
            {image_2 &&
              <div className={`image-wrapper ${image_position_2}`}>
                <img src={image_2} alt={alt_2}/>
              </div>
            }
        </div>
    </section>
  )
}